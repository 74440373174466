class ContactSupportDialog {
    protected modalSelector: JQuery<HTMLElement> = $('#supportModal');
    protected lastFocus : any;
    protected focusableElements = 'button:visible, [href]:visible, input:visible, select:visible, textarea:visible, [tabindex]:not([tabindex="-1"])';

    constructor() {
        var self = this;

        this.modalSelector.off();
        this.modalSelector.on('hidden.bs.modal',
            function() {
                $(this).data('bs.modal', null);
                if (self.lastFocus) 
                    self.lastFocus.focus();
         });
        this.modalSelector.on('shown.bs.modal', function(e) {
            e.currentTarget.focus();
        });

        //this function traps the tab/shift tab into the modal only - bootstrap does not do this correctly
        this.modalSelector.on('keydown', 
            function(e) {
                var focusableContent = self.modalSelector.children().find(self.focusableElements);
                var firstFocusableElement = focusableContent[0]; // get first element to be focused inside modal
                var lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

                let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey) { // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else { // if tab key is pressed
                    if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                        firstFocusableElement.focus(); // add focus for the first focusable element
                        e.preventDefault();
                    }
                }
        });
    }
}